// 考勤-排班
import { ATTENDACE } from '../api'
import { METHOD, request } from '@/utils/request'

// 考勤-排班-排班表

// 获取员工排班表列表
export async function getStaffShiftListPageApi(params) {
  return request(`${ATTENDACE}/shift/getStaffShiftListPage`, METHOD.GET, params)
}
// 调班详情
export async function getStaffShiftChangeLogInfoApi(params) {
  return request(`${ATTENDACE}/shift/getStaffShiftChangeLogInfo`, METHOD.GET, params)
}
// 概览
export async function getStaffShiftGeneralViewApi(params) {
  return request(`${ATTENDACE}/shift/getStaffShiftGeneralView`, METHOD.GET, params)
}
// 班次列表
export async function getShiftListApi(params) {
  return request(`${ATTENDACE}/config/shift/getShiftList`, METHOD.GET, params)
}
// 考勤组名称列表
export async function attendanceGroupNameListApi(params) {
  return request(`${ATTENDACE}/config/attendanceGroup/attendanceGroupNameList`, METHOD.GET, params)
}
// 修改班次
export async function updateStaffShiftApi(params) {
  return request(`${ATTENDACE}/shift/updateStaffShift`, METHOD.POST, params)
}
// 导出员工排班表
export async function exportStaffShiftListPageApi(params) {
  return request(`${ATTENDACE}/shift/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
// 下载批量导入员工排班表excel模板
export async function getImportTemplateShiftApi(params) {
  return request(`${ATTENDACE}/shift/getImportTemplate`, METHOD.GET, params)
}
// 批量导入员工排班表数据
export async function batchImportApi(params) {
  return request(`${ATTENDACE}/shift/batchImport`, METHOD.POST, params)
}

// 考勤-排班-调班

// 调班记录列表
export async function getStaffApplyChangeShiftListPageApi(params) {
  return request(`${ATTENDACE}/applyChangeShift/getStaffApplyChangeShiftListPage`, METHOD.GET, params)
}
// 添加调换班
export async function insertStaffApplyChangeShiftApi(params) {
  return request(`${ATTENDACE}/applyChangeShift/insertStaffApplyChangeShift`, METHOD.POST, params)
}
// 换班内容查询
export async function getExchangeShiftDescribeApi(params) {
  return request(`${ATTENDACE}/applyChangeShift/getExchangeShiftDescribe`, METHOD.GET, params)
}
// 换班内容查询
export async function getChangeShiftDescribeApi(params) {
  return request(`${ATTENDACE}/applyChangeShift/getChangeShiftDescribe`, METHOD.GET, params)
}
// 删除调换班
export async function deleteStaffApplyChangeShiftApi(params) {
  return request(`${ATTENDACE}/applyChangeShift/deleteStaffApplyChangeShift`, METHOD.POST, params)
}
// 导出调换班记录
export async function exportApplyChangeShiftApi(params) {
  return request(`${ATTENDACE}/applyChangeShift/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
// 员工考勤日班次信息
export async function getStaffShiftShiftApi(params) {
  return request(`${ATTENDACE}/shift/getStaffShiftShift`, METHOD.GET, params)
}
// 下载批量导入员工排班表excel模板
export async function getImportTemplateApplyChangeShiftApi(params) {
  return request(`${ATTENDACE}/applyChangeShift/getImportTemplate`, METHOD.GET, params)
}
// 批量导入添加调换班
export async function batchImportStaffApplyChangeShiftApi(params) {
  return request(`${ATTENDACE}/applyChangeShift/batchImport`, METHOD.POST, params)
}

// 考勤-排班-统计

// 统计
export async function staffShiftCountApi(params) {
  return request(`${ATTENDACE}/shift/totalStatistics`, METHOD.GET, params)
}
// 统计-日排班统计
export async function dayDetailApi(params) {
  return request(`${ATTENDACE}/shift/dayStatisticsList`, METHOD.GET, params)
}
// 统计-周班统计
export async function weekDetailApi(params) {
  return request(`${ATTENDACE}/shift/weekStatisticsList`, METHOD.GET, params)
}
